import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DistributorDistributor} from "../interfaces/distributors.interface";

interface InitialState {
    activeDistributor: DistributorDistributor | null
    distributors: DistributorDistributor[]
    bigDistributor: DistributorDistributor | null
    activeRegion: DistributorDistributor | null
    activeCountry: 'Казахстан' | 'Россия' | 'Беларусь'
}


const initialState: InitialState = {
    activeDistributor: null,
    activeRegion: null,
    activeCountry: 'Россия',
    distributors: [],
    bigDistributor: null
}

const distributorsSlice = createSlice({
    name: 'distributors',
    initialState,
    reducers: {
        setDistributor(state, action: {payload: DistributorDistributor }) {
            state.activeDistributor = action.payload
        },
        setAllDistributor(state, action: {payload: DistributorDistributor[] }) {
            state.distributors = action.payload
        },
        setBigDistributor(state, action: {payload: DistributorDistributor }) {
            state.bigDistributor = action.payload
        },
        setCountry(state, action: PayloadAction<'Казахстан' | 'Россия' | 'Беларусь'>) {
            state.activeCountry = action.payload
        },
        setRegion(state, action: PayloadAction<DistributorDistributor>) {
            state.activeRegion = action.payload
        },
    }
})

export const {reducer: distributorsReducer, actions: distributorsActions} = distributorsSlice