import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {ICategory, IProductCategory} from "../../interfaces/categoryProducts.interface";
import {IQuery} from "../../interfaces/query.interface";

const API_URL = process.env.NEXT_PUBLIC_FRONT_PROXY_API_URL

const VERCEL_URL = process.env.NEXT_PUBLIC_VERCEL_URL

export const productsApi = createApi({
    reducerPath: 'productsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: VERCEL_URL ? `https://${VERCEL_URL}/proxy/api` : API_URL,
        cache: "default",
    }),
    endpoints: (builder) => ({
        getProducts: builder.query<ICategory, IQuery>({
            query: (name) => {
                const queryString = `&${name.category.map(e => `categories[]=${e}&`).join('')}&${name.tags.map(e => `tags[]=${e}&`).join('')}&per_page=${name.pageSize}&page=${name.page}`
                return {
                    url: `/products?${queryString}`,
                }
            },
        }),
    })
})

export const { useGetProductsQuery } = productsApi
