
const BASE_URL = process.env.NEXT_PUBLIC_FRONT_API_URL

export const API_SSR = {
    getContactsPage: `${BASE_URL}/contacts`,
    getCategoriesPage: `${BASE_URL}/categories`,
    getNewsCategories: `${BASE_URL}/news/categories`,
    getCatalogPage: `${BASE_URL}/catalog`,
    getMainPage: `${BASE_URL}/home`,
    getRegions: (name: string) => `${BASE_URL}/regions/?country=${name}`,
    getNews: `${BASE_URL}/news`,
    getDocumentation: `${BASE_URL}/technical-documentation`,
    getDocuments: `${BASE_URL}/technical-docs`,
    getVideos: `${BASE_URL}/technical-docs/videos`,
    getDistributorsPage: `${BASE_URL}/distributors`,
    getAboutPage: `${BASE_URL}/about`,
    getDetailProduct: (slug: string) => `${BASE_URL}/products/${slug}`,
    getProductsByCategory: (slug: string) => `${BASE_URL}/products?${slug}`,
    getNewsByCategory: (slug: string) => `${BASE_URL}/news?${slug}`,
    getNewsById: (id: string | string[]) => `${BASE_URL}/news/${id}`,
    getProductsBySearch: `${BASE_URL}/products/search`,
    getSks: `${BASE_URL}/sks`,
};

export const API = {
    getContactsPage: '/contacts',
    getCategoriesPage: '/categories',
    getCatalogPage: '/catalog',
    getMainPage: '/home',
    getRegions:(name: string) => `/regions/?country=${name}`,
    getNews: '/news',
    getDocumentation: '/technical-documentation',
    getDocuments: `/technical-docs`,
    getVideos: `/technical-docs/videos`,
    getDistributorsPage: '/distributors',
    getAboutPage: '/about',
    getForm: '/crm.lead.add.json',
    getDetailProduct: (slug: string) => `/products/${slug}`,
    getProductsByCategory: (slug: string) => `/products?${slug}`,
    getProductsBySearch: `/products/search`,
};

