import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface InitialState {
    tags: number[],
    categories: number[]
    activeCategory: string
    page: number,
    countProducts: number
    pageSize: number,
    openTags: boolean
}


const initialState: InitialState = {
    tags: [],
    activeCategory: '',
    categories: [],
    openTags: false,
    page: 1,
    countProducts: 12,
    pageSize: 12,
}

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        showTags(state) {
            state.openTags = true
        },
        hideTags(state) {
            state.openTags = false
        },
        setCategory(state, action: PayloadAction<number>) {
            state.categories.push(action.payload)
        },
        setActiveCategory(state, action: PayloadAction<string>) {
            state.activeCategory = action.payload
        },
        setRadioCategory(state, action: PayloadAction<number>) {
            state.categories = [action.payload]
        },
        removeCategory(state, action: PayloadAction<number>) {
            state.categories = state.categories.filter(id => id != action.payload)
        },
        setTags(state, action: PayloadAction<number>) {
            state.tags.push(action.payload)
        },
        removeTag(state, action: PayloadAction<number>) {
            state.tags = state.tags.filter(id => id != action.payload)
        },
        setPage(state, action: PayloadAction<number>) {
            state.page = action.payload
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.pageSize = action.payload
        },
        throwTags(state) {
            state.tags = []
        },
        setLengthProducts(state, action: PayloadAction<number>) {
            state.countProducts = action.payload
        },
    }
})

export const {reducer: productsReducer, actions: productsActions} = productsSlice