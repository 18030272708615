import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TABS} from "../pages/tech-documentation";
import {CategoryDocumentation} from "../interfaces/documentation";

interface InitialState {
  activeTab: CategoryDocumentation | null
}


const initialState: InitialState = {
  activeTab: null
}

const documentationSlice = createSlice({
  name: 'documentation',
  initialState,
  reducers: {
    setTab(state, action: PayloadAction<CategoryDocumentation>) {
      state.activeTab = action.payload
    },
  }
})

export const {reducer: documentationReducer, actions: documentationActions} = documentationSlice