import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {API} from "../../api/api";
import {ICategories} from "../../interfaces/categories.interface";

const BASE_API_URL = process.env.NEXT_PUBLIC_FRONT_API_URL;
const PROXY_BASE_API_URL = process.env.NEXT_PUBLIC_FRONT_PROXY_API_URL;
const VERCEL_URL = process.env.NEXT_PUBLIC_VERCEL_URL

export const categoriesApi = createApi({
    reducerPath: 'categoriesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: VERCEL_URL ? `https://${VERCEL_URL}/proxy/api` : PROXY_BASE_API_URL,
        cache: "default",
    }),
    endpoints: (builder) => ({
        getCategories: builder.query<ICategories, void>({
            query: () =>  API.getCategoriesPage,
        }),
    })
})

export const { useGetCategoriesQuery } = categoriesApi
