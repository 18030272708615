import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {ICategory, IProductCategory, ISearchCategory} from "../../interfaces/categoryProducts.interface";
import {IQuery} from "../../interfaces/query.interface";
import {API} from "../../api/api";

const API_URL = process.env.NEXT_PUBLIC_FRONT_PROXY_API_URL

const VERCEL_URL = process.env.NEXT_PUBLIC_VERCEL_URL

export const searchApi = createApi({
    reducerPath: 'searchApi',
    baseQuery: fetchBaseQuery({
        baseUrl: VERCEL_URL ? `https://${VERCEL_URL}/proxy/api` : API_URL,
        cache: "default",
    }),
    endpoints: (builder) => ({
        getSearch: builder.query<ISearchCategory, string>({
            query: (search) => {
                return {
                    url: API.getProductsBySearch,
                    method: 'GET',
                    params: {
                        search: search
                    }
                }
            },
        }),
    })
})

export const { useGetSearchQuery } = searchApi
