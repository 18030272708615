import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface InitialState {
    currentCategory?: string,
    page: number,
    countNews: number,
    pageSize: number
}

const initialState: InitialState = {
    currentCategory: null,
    page: 1,
    countNews: 12,
    pageSize: 12,
}

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setPage(state, action: PayloadAction<number>) {
            state.page = action.payload
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.pageSize = action.payload
        },
        setLengthNews(state, action: PayloadAction<number>) {
            state.countNews = action.payload
        },
    }
})

export const {reducer: newsReducer, actions: newsActions} = newsSlice
