import '../styles/globals.css'
import type { AppProps } from 'next/app'
import Head from "next/head";
import Modal from "../components/Modal";
import { Provider } from "react-redux";
import store from "../store/store";

export default function App({ Component, pageProps }: AppProps) {
    return (
        <>
            <Head>
                <title>SPL</title>
                <meta name="description" content="SPL — Один из ведущих производителей электротехнических и слаботочных решений на Российском рынке. Оставить заявку. МоскваЦентральный склад."/>
                <link rel="icon" href="/favicon.png" />
                <script defer src={`https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=${process.env.NEXT_PUBLIC_YANDEX_MAP_API_KEY}`} type="text/javascript"></script>
                <meta name="yandex-verification" content="caf398f59c99fe11" />

                {/* Yandex.Metrika counter */}
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                            m[i].l=1*new Date();
                            for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
                            k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
                            ym(97292668, "init", {
                                clickmap:true,
                                trackLinks:true,
                                accurateTrackBounce:true,
                                webvisor:true
                            });
                        `
                    }}
                />

                {/* Google Tag Manager */}
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-T7BF3G99');
                        `
                    }}
                />
            </Head>

            <noscript>
                <div>
                    <img src="https://mc.yandex.ru/watch/97292668" style={{ position: "absolute", left: "-9999px" }} alt="" />
                </div>
            </noscript>

            {/* Google Tag Manager (noscript) */}
            <noscript>
                <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T7BF3G99"
                        height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe>
            </noscript>

            <div className='page-layout'>
                <Provider store={store}>
                    <Component {...pageProps} />
                </Provider>
            </div>
        </>
    );
}
