import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { ISearchCategory} from "../interfaces/categoryProducts.interface";

interface InitialState {
    filteredProducts: ISearchCategory,
    allProducts: ISearchCategory,
    activeCategory: number
}


const initialState: InitialState = {
    filteredProducts: {data: [], categories_ids: []},
    allProducts: {data: [], categories_ids: []},
    activeCategory: 0
}

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setProducts(state, action: PayloadAction<ISearchCategory>) {
            state.allProducts = action.payload
            state.filteredProducts = action.payload
        },
        filterProducts(state, action: PayloadAction<number>) {
            state.filteredProducts.data = state.allProducts.data.filter(item =>
                item.categories_ids.includes(action.payload) ||
                item.root_categories_ids.includes(action.payload)
            )
        },
        setCategory(state, action: PayloadAction<number>) {
            state.activeCategory = action.payload
        },
        throwProducts(state) {
            state.filteredProducts = state.allProducts
        }
    }
})

export const {reducer: searchReducer, actions: searchActions} = searchSlice