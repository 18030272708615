import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {API} from "../../api/api";
import {ICategories} from "../../interfaces/categories.interface";
import {createApi} from "@reduxjs/toolkit/query/react";
import {IFileItem, IVideos} from "../../interfaces/documentation";

const BASE_API_URL = process.env.NEXT_PUBLIC_FRONT_API_URL;
const PROXY_BASE_API_URL = process.env.NEXT_PUBLIC_FRONT_PROXY_API_URL;
const VERCEL_URL = process.env.NEXT_PUBLIC_VERCEL_URL

export const categoriesDocsApi = createApi({
  reducerPath: 'categoriesDocsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: VERCEL_URL ? `https://${VERCEL_URL}/proxy/api` : PROXY_BASE_API_URL,
    cache: "default",
  }),
  endpoints: (builder) => ({
    getCategoriesDocs: builder.query<IFileItem[], number>({
      query: (v) => ({
        url: API.getDocuments,
        params: {
          category_id: v
        }
      })
    }),
    getVideos: builder.query<IVideos, number>({
      query: (v) => ({
        url: API.getVideos,
        params: {
          category_id: v
        }
      })
    }),
  })
})

export const { useGetCategoriesDocsQuery, useGetVideosQuery } = categoriesDocsApi
