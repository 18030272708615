import {combineReducers, createStore} from "redux";
import {reducer} from "next/dist/client/components/reducer";
import {configureStore} from "@reduxjs/toolkit";
import {distributorsReducer} from "./distributorsSlice";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {productsApi} from './api/productsApi'
import {productsReducer} from "./productSlice";
import {newsReducer} from "./newsSlice";
import {categoriesApi} from "./api/categoriesApi";
import {categoriesDocsApi} from "./api/categoryDocsApi";
import {searchApi} from "./api/searchApi";
import {searchReducer} from "./searchSlice";
import {documentationReducer} from "./documentationSlice";
import { newsApi } from "./api/newsApi"

const store = configureStore({
    reducer: combineReducers({
        documentationReducer,
        distributorsReducer,
        productsReducer,
        searchReducer,
        newsReducer,
        [categoriesApi.reducerPath]: categoriesApi.reducer,
        [categoriesDocsApi.reducerPath]: categoriesDocsApi.reducer,
        [productsApi.reducerPath]: productsApi.reducer,
        [searchApi.reducerPath]: searchApi.reducer,
        [newsApi.reducerPath]: newsApi.reducer,
    }),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
      .concat(categoriesApi.middleware, productsApi.middleware, searchApi.middleware, categoriesDocsApi.middleware, newsApi.middleware),
    devTools: true
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
