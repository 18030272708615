import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {ICategory, IProductCategory} from "../../interfaces/categoryProducts.interface";
import {IQuery} from "../../interfaces/query.interface";
import { INewsQuery } from "../../interfaces/newsQuery.interface"
import { INewsCatalog } from "../../interfaces/newsCatalog.interface"

const API_URL = process.env.NEXT_PUBLIC_FRONT_PROXY_API_URL

const VERCEL_URL = process.env.NEXT_PUBLIC_VERCEL_URL

export const newsApi = createApi({
    reducerPath: 'newsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: VERCEL_URL ? `https://${VERCEL_URL}/proxy/api` : API_URL,
        cache: "default",
    }),
    endpoints: (builder) => ({
        getNews: builder.query<INewsCatalog, INewsQuery>({
            query: (params) => {
                const queryString = `category=${params.category}&per_page=${params.pageSize}&page=${params.page}`
                return {
                    url: `/news?${queryString}`,
                }
            },
        }),
    })
})

export const { useGetNewsQuery } = newsApi
